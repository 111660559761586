<template>
  <v-card class="card-shadow border-radius-xl">
    <v-dialog v-model="editItem.dialog" scrollable width="60%">
      <v-card>
        <v-card-title class="text-lg font-weight-bolder ms-1">{{
          dialog_title
        }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="1">
              <label class="text-sm font-weight-bolder ms-1">名稱</label>
            </v-col>
            <v-col cols="autos">
              <v-text-field
                hide-details
                outlined
                v-model="editItem.name"
                class="text-typo font-size-input border"
                :disabled="editItem.disabled"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">
              <label class="text-sm font-weight-bolder ms-1">父群組</label>
            </v-col>
            <v-col cols="autos">
              <v-text-field
                hide-details
                outlined
                readonly
                disabled
                v-model="editItem.parent_name"
                :class="text_edit_class"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            class="
              font-weight-bold
              text-xs
              btn-default
              bg-gradient-secondary
              px-6
              py-2
              mt-2 mt-8
            "
            @click="onClose()"
          >
            {{ $t("Close") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="
              font-weight-bold
              text-xs
              bg-gradient-primary
              py-5
              px-6
              mt-6
              mb-2
              me-2
              ms-auto
              text-white
            "
            @click="onActionConfirmed()"
          >
            {{ dialog_confirm_title }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title class="justify-center py-2 px-4">
      <h5 class="font-weight-bold text-h5 text-grt mb-0">群組管理</h5>
      <v-spacer></v-spacer>
      <v-btn
        :ripple="false"
        elevation="0"
        color="#fff"
        class="
          font-weight-bolder
          btn-default
          bg-gradient-primary
          py-5
          px-4
          mx-2
        "
        small
        @click="onNewClick()"
      >
        <v-icon class="me-2" size="12">fa fa-power-off</v-icon>
        新增群組
      </v-btn>
      <v-btn
        :ripple="false"
        elevation="0"
        color="#fff"
        class="
          font-weight-bolder
          btn-default
          bg-gradient-default
          py-5
          px-4
          mx-2
        "
        small
        @click="onNewSubClick()"
      >
        <v-icon class="me-2" size="12">fa fa-power-off</v-icon>
        新增子群組
      </v-btn>
      <v-btn
        :ripple="false"
        elevation="0"
        color="#fff"
        class="
          font-weight-bolder
          btn-default
          bg-gradient-secondary
          py-5
          px-4
          mx-2
        "
        small
        @click="onEditClick()"
      >
        <v-icon class="me-2" size="12">fa fa-power-off</v-icon>
        修改群組
      </v-btn>
      <v-btn
        :ripple="false"
        elevation="0"
        color="#fff"
        class="font-weight-bolder btn-default bg-gradient-danger py-5 px-4 mx-2"
        small
        @click="onDeleteClick()"
      >
        <v-icon class="me-2" size="12">fa fa-power-off</v-icon>
        刪除群組
      </v-btn>
    </v-card-title>

    <v-treeview
      activatable
      color="danger"
      :items="items"
      item-text="label"
      @update:active="onUpdate"
    ></v-treeview>
  </v-card>
</template>
<script>
import CommonLoading from "@/views/Pages/General/Widgets/CommonLoading.vue";
import CustomerGroupMixin from "@/views/Pages/CRM/CustomerGroupMixin.vue";
import _ from "lodash";
import { common_text_edit_class } from "@/definitions";
// import the component
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  name: "case-base",
  mixins: [CustomerGroupMixin],
  data: (vm) => ({
    items: [],
    items_count: 0,
    itemsPerPage: 10,
    loading: false,
    page: 1,
    options: {},
    headers: [
      {
        text: "名稱",
        cellClass: "border-bottom",
        sortable: false,
        value: "name",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
      {
        text: "描述",
        cellClass: "border-bottom",
        sortable: false,
        value: "description",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
      {
        text: "階層",
        cellClass: "border-bottom",
        sortable: false,
        value: "display",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
    ],
    data_loading: false,

    editItem: {
      dialog: false,
      name: "",
      parent_name: null,
      parent_id: null,
      mode: "edit",
      disabled: false,
    },
    text_edit_class: common_text_edit_class,
  }),
  components: {
    CommonLoading,
    Treeselect,
  },
  computed: {
    dialog_title() {
      if (this.editItem.mode === "new") {
        return "新增群組";
      } else if (this.editItem.mode === "new_sub") {
        return "新增子群組";
      } else if (this.editItem.mode === "delete") {
        return "刪除群組";
      } else {
        return "修改群組";
      }
    },
    dialog_confirm_title() {
      if (this.editItem.mode === "new") {
        return "新增";
      } else if (this.editItem.mode === "new_sub") {
        return "新增";
      } else if (this.editItem.mode === "delete") {
        return "刪除";
      } else {
        return "修改";
      }
    },
  },
  watch: {
    page() {
      this.getAllGroups();
    },
    options: {
      handler() {
        this.getAllGroups();
      },
    },
  },
  mounted() {
    this.getAllGroups();
  },
  methods: {
    setResult(data) {
      this.items = data;
      this.items_count = data.length;
      // emit the result to the parent
      this.$emit("refresh_group", this.group_options);
    },
    getPageCount() {
      return Math.ceil(this.items_count / this.itemsPerPage);
    },
    onUpdate(selection) {
      if (selection.length == 0) {
        this.editItem = {
          dialog: false,
          name: "",
          parent_name: null,
          parent_id: null,
          mode: "edit",
        };
        return;
      }
      this.editItem.selected = selection[0];
    },
    getInfo() {
      let selection = this.editItem.selected;
      this.editItem.name = this.group_ids_map[selection];
      this.editItem.parent_id = this.getParentIdById(selection);
      this.editItem.parent_name = this.group_ids_map[this.editItem.parent_id];
      this.editItem.disabled = false;
    },
    onEditClick() {
      this.getInfo();
      this.editItem.mode = "edit";
      this.editItem.dialog = true;
    },
    onNewClick() {
      this.getInfo();
      this.editItem.name = "";
      this.editItem.mode = "new";
      this.editItem.dialog = true;
    },
    onNewSubClick() {
      this.editItem.disabled = false;
      this.editItem.parent_id = this.editItem.selected;
      this.editItem.parent_name = this.group_ids_map[this.editItem.parent_id];
      this.editItem.name = "";
      this.editItem.mode = "new_sub";
      this.editItem.dialog = true;
    },
    onDeleteClick() {
      this.getInfo();
      let children = this.getChildrenById(this.editItem.selected);
      if (children.length > 0) {
        alert("有其他子群組，請先刪除子群組");
        return;
      }
      this.editItem.disabled = true;
      this.editItem.mode = "delete";
      this.editItem.dialog = true;
    },
    onClose() {
      this.editItem.dialog = false;
    },
    onActionConfirmed() {
      console.log("onActionConfirmed", this.editItem);
      if (this.editItem.mode === "new" || this.editItem.mode === "new_sub") {
        this.createGroup(this.editItem.name, this.editItem.parent_id);
      } else if (this.editItem.mode === "delete") {
        this.removeGroup(this.editItem.selected);
      } else {
        this.updateGroup(
          this.editItem.selected,
          this.editItem.name,
          this.editItem.parent_id
        );
      }

      this.onClose();
    },
  },
};
</script>

<style>
.v-data-table-header-mobile {
  display: none !important;
}
.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 1px;
}
/* .theme--light.v-treeview {
  color: blue !important;
} */
</style>
